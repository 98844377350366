/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Card,
  Typography,
  Select,
  Radio,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import Spinner from "../../common/spinner";
import { setOtpEmail } from "../../../utils/redux/actions";
import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";
import { setUserData } from "../../../utils/redux/actions";

import authImg from "../../../images/loginImg.svg";
// import logo from "../../../images/tajira-logo.png";

import "./styles.scss";

const { Title } = Typography;
const { Option } = Select;

const SignIn = () => {
  const [form] = Form.useForm();
  const auth = new API.Auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState();
  const [userEmail, setUserEmail] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState();

  useEffect(() => {
    FetchCountries();
  }, []);

  // const handleRefresh = () => {
  //   window.location.reload(false);
  // };

  const FetchCountries = () => {
    getDataManager(auth?.getCountry, setLoading).then((x) => {
      if (x?.status === true) {
        setCountries(x?.data);
      } else {
        const error = getErrorMessage(x?.errors);

        message.error({
          content:
            x?.message ||
            error ||
            "Error ocurred while fetching countries list",
          duration: 3,
        });
      }
    });
  };

  const SignUp = async (payload) => {
    return getDataManager(auth?.signUpUser, setLoading, payload).then((x) => {
      if (x?.status === true) {
        message.success({
          content: "Otp Sent to Email",
          duration: 3,
        });
        setUserEmail(payload?.email);
        setShowOtp(true);
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 3,
        });
        navigate(`/email-verification/${payload?.email}`);
      }
    });
  };

  const onFinish = (values) => {
    SignUp(values);
  };
  const verifyUserEmail = () => {
    if (otp.length === 6) {
      const payload = {
        code: otp,
        email: userEmail,
      };
      getDataManager(auth?.validateUserEmail, setLoading, payload).then((x) => {
        if (x?.status === true) {
          message.success({
            content: "You have successfully signed into Fast Cash user panel",
            duration: 3,
          });
          navigate("/login");
        } else {
          const error = getErrorMessage(x?.errors);

          message.error({
            content: x?.message || error || "Error ocurred while verification",
            duration: 3,
          });
        }
      });
    } else {
      message.error({ content: "Please enter the otp!", duration: 3 });
    }
  };
  const resendVerificationCode = () => {
    const payload = {
      email: userEmail,
    };
    getDataManager(auth?.resendOtp, setLoading, payload).then((x) => {
      if (x?.status === true) {
        message.success({
          content: "Otp code sent again",
          duration: 3,
        });
        setOtp("");
      } else {
        const error = getErrorMessage(x?.errors);

        message.error({
          content: x?.message || error || "Error ocurred while verification",
          duration: 3,
        });
      }
    });
  };

  return (
    <Row>
      <Col span={12} className="signin-right-section">
        <img src={authImg} alt="" />
      </Col>
      <Col span={12} className="sign-in-left-section">
        {!showOtp ? (
         <Card className="login-card" bordered={false}>
         {loading && <Spinner />}
         <Title level={4} className="login-title">
           Create An Account
         </Title>
         <Form
           form={form}
           onFinish={onFinish}
           scrollToFirstError
           layout="vertical"
         >
           <Row gutter={[24, 12]}>
             <Col span={12}>
               <Form.Item
                 label="First Name"
                 name="first_name"
                 rules={[
                   {
                     required: true,
                     message: "Please enter first name",
                   },
                 ]}
               >
                 <Input
                   placeholder="First Name"
                   size="large"
                   className="form-input"
                 />
               </Form.Item>
             </Col>
             <Col span={12}>
               <Form.Item
                 label="Last Name"
                 name="last_name"
                 rules={[
                   {
                     required: true,
                     message: "Please enter last name",
                   },
                 ]}
               >
                 <Input
                   placeholder="Last Name"
                   size="large"
                   className="form-input"
                 />
               </Form.Item>
             </Col>
             <Col span={12}>
               <Form.Item
                 label="User Name"
                 name="user_name"
                 rules={[
                   {
                     required: true,
                     message: "Please enter user name",
                   },
                 ]}
                 className="password-field"
               >
                 <Input
                   placeholder="User Name"
                   size="large"
                   className="form-input"
                 />
               </Form.Item>
             </Col>
             <Col span={12}>
               <Form.Item
                 label="Password"
                 name="password"
                 rules={[
                   {
                     required: true,
                     message: "Please enter password",
                   },
                 ]}
                 className="password-field"
               >
                 <Input
                   placeholder="Password"
                   visible={true}
                   type="password"
                   size="large"
                   className="form-input"
                   iconRender={(visible) =>
                     visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                   }
                 />
               </Form.Item>
             </Col>
             <Col span={12}>
               <Form.Item
                 label="Email"
                 name="email"
                 rules={[
                   {
                     required: true,
                     message: "Please enter email",
                   },
                 ]}
                 className="password-field"
               >
                 <Input
                   placeholder="Email"
                   size="large"
                   className="form-input"
                 />
               </Form.Item>
             </Col>
             <Col span={12}>
               <Form.Item
                 label="Phone"
                 name="mobile"
                 rules={[
                   {
                     required: true,
                     message: "Please enter mobile number",
                   },
                 ]}
               >
                 <Input
                   placeholder="Phone"
                   size="large"
                   className="form-input"
                 />
               </Form.Item>
             </Col>
             <Col span={12}>
               <Form.Item
                 label="Country"
                 name="country"
                 rules={[
                   {
                     required: true,
                     message: "Please select country",
                   },
                 ]}
               >
                 <Select
                   placeholder="Select Country"
                   size="large"
                   className="deposits-input-container"
                   bordered={false}
                 >
                   {countries?.map((e) => (
                     <Option value={e?._id}>{e?.name}</Option>
                   ))}
                 </Select>
               </Form.Item>
             </Col>
             <Col span={24}>
               <Form.Item name="user_role">
                 <Radio.Group className="radio-inputs">
                   <Radio value="merchant">Merchant</Radio>
                   <Radio value="user">User</Radio>
                   <Radio value="agent">Agent</Radio>
                 </Radio.Group>
               </Form.Item>
             </Col>
             <Form.Item>
               <Button htmlType="submit" size="large" type="danger">
                 Save and Continue
               </Button>
             </Form.Item>
           </Row>
         </Form>
         <div className="sign-in-dont-have-account">
           Already have an account?&nbsp;
           <span className="login-text" onClick={() => navigate("/login")}>
             Log in
           </span>
         </div>
       </Card>
        ) : (
          <Card className="login-card" bordered={false}>
            {loading && <Spinner />}
            <Title level={4} className="login-title">
              OTP Verification
            </Title>
            <div className="otp-code-text">
              We have sent a one time password to your email address
            </div>
            <div className="otp-code-text-label">
              Please enter the otp code to verify your account.
            </div>
            <Form
              form={form}
              layout="vertical"
              onFinish={verifyUserEmail}
              scrollToFirstError
            >
              <Form.Item>
                <OtpInput
                  numInputs={6}
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  containerStyle="verification-code-container"
                  inputStyle="one-digit-input"
                />
              </Form.Item>
              <div
                className="didnot-receive"
                onClick={() => resendVerificationCode()}
              >
                I didn’t receive any code, RESEND
              </div>

              <Form.Item>
                <Button
                  htmlType="submit"
                  size="large"
                  type="danger"
                  className="forget-submit-button"
                >
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default SignIn;
