import react, { useState, useEffect } from "react";
import { Layout, Menu, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import ArrowDownIcon from "../../../images/ArrowLineDown-s.svg";
import LogoImg from "../../../images/wallet-logo-pngdark 1.svg";
import HomeIcon from "../../../images/ChartPieSlice.svg";
import currencyIcon from "../../../images/manage currency.svg";
import TransactiontIcon from "../../../images/transaction charges.svg";
import ProfitLogsIcon from "../../../images/profit logs.svg";
import ManageUserIcon from "../../../images/users.svg";
import ManageAgentsIcon from "../../../images/agent.svg";
import MerchantIcon from "../../../images/merchant.svg";
import PaymentIcon from "../../../images/payment gateway.svg";
import KycIcon from "../../../images/kyc.svg";
import DepositsIcon from "../../../images/deposit.svg";
import WithdrawIcon from "../../../images/withdraw money.svg";
import TicketIcon from "../../../images/Ticket.svg";
import ReportIcon from "../../../images/report.svg";
import SubscribeIcon from "../../../images/subscriber.png";
import SettingIcon from "../../../images/setting.png";
import ConfigIcon from "../../../images/System Configuration.png";
import QrIcon from "../../../images/QR.png";
import LogoIcon from "../../../images/Logo and favicon.png";
import ExtensionIcon from "../../../images/extension.png";
import LanguageIcon from "../../../images/Language.png";
import SeoIcon from "../../../images/seo manager.png";
import NotificationIcon from "../../../images/notification (1).png";
import TemplateIcon from "../../../images/manage Template.png";
import PagesIcon from "../../../images/manage pages.png";
import SectionIcon from "../../../images/manage sections.png";
import ApiIcon from "../../../images/api plugins.png";
import MaintainaceIcon from "../../../images/Maintenance mode.png";
import GdprIcon from "../../../images/GDPR cookie.png";
import SystemIcon from "../../../images/System.png";
import CustomIcon from "../../../images/Css.png";
import RequestIcon from "../../../images/report and request.png";
import ModuleIcon from "../../../images/module.png";
import "./styles.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SiderBar = ({ toggle, collapsed }) => {
  const rootSubmenuKeys = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
  ];
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([""]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <Sider collapsible onCollapse={toggle}>
      <div className="laststand-logo">
        {collapsed ? (
          <img className="collapsed-img" src={LogoImg} alt="" />
        ) : (
          <img className="not-collapsed-img" src={LogoImg} alt="" />
        )}
      </div>
      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        onOpenChange={onOpenChange}
        onClick={() => setOpenKeys([""])}
        openKeys={openKeys}
      >
        <Menu.Item
          key="1"
          onClick={() => navigate("/")}
          icon={<img src={HomeIcon} alt="" />}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => navigate("/manage-currencies")}
          icon={<img src={currencyIcon} alt="" />}
        >
          Manage Currency
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => navigate("/transaction-charges")}
          icon={<img src={TransactiontIcon} alt="" />}
        >
          Transaction Charges
        </Menu.Item>
        <div className="menu-heading">Profit Logs</div>
        <SubMenu
          key="4"
          style={{ borderLeft: "0px" }}
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={ProfitLogsIcon} alt="" />
            </Space>
          }
          title=" Profit Logs"
        >
          <Menu.Item key="4a" onClick={() => navigate("/all-logs")}>
            All Logs
          </Menu.Item>
          <Menu.Item key="4b" onClick={() => navigate("/only-profit")}>
            Only Profit
          </Menu.Item>
          <Menu.Item key="4c" onClick={() => navigate("/only-commission")}>
            Only Commission
          </Menu.Item>
        </SubMenu>

        <div className="menu-heading">Manage Users</div>
        <SubMenu
          key="5"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={ManageUserIcon} alt="" />
            </Space>
          }
          title=" Users"
        >
          <Menu.Item key="5a" onClick={() => navigate("/admin-users")}>
            Admin users
          </Menu.Item>
          <Menu.Item key="5b" onClick={() => navigate("/active-users")}>
            Active users
          </Menu.Item>
          <Menu.Item key="5c" onClick={() => navigate("/banned-users")}>
            Banned Users
          </Menu.Item>
          <Menu.Item
            key="5d"
            onClick={() => navigate("/email-unverified-users")}
          >
            Email Unverified
          </Menu.Item>
          <Menu.Item
            key="5e"
            onClick={() => navigate("/mobile-unverified-users")}
          >
            Mobile Unverified
          </Menu.Item>
          <Menu.Item key="5f" onClick={() => navigate("/kyc-unverified-users")}>
            KYC Unverified
          </Menu.Item>
          <Menu.Item key="5g" onClick={() => navigate("/kyc-pending")}>
            KYC Pending
          </Menu.Item>
          <Menu.Item key="5h" onClick={() => navigate("/with-balance")}>
            With Balance
          </Menu.Item>
          <Menu.Item key="5i" onClick={() => navigate("/all-users")}>
            All Users
          </Menu.Item>
          {/* <Menu.Item key="5j" onClick={() => navigate("/notification-all")}>
            Notification to All
          </Menu.Item> */}
        </SubMenu>
        {/* Agents */}
        <SubMenu
          key="6"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={ManageAgentsIcon} alt="" />
            </Space>
          }
          title="Agents"
        >
          <Menu.Item key="6a" onClick={() => navigate("/active-agents")}>
            Active Agents
          </Menu.Item>
          <Menu.Item key="6b" onClick={() => navigate("/banned-agents")}>
            Banned Agents
          </Menu.Item>
          <Menu.Item
            key="6c"
            onClick={() => navigate("/email-unverified-agents")}
          >
            Email Unverified
          </Menu.Item>
          <Menu.Item
            key="6d"
            onClick={() => navigate("/mobile-unverified-agents")}
          >
            Mobile Unverified
          </Menu.Item>
          <Menu.Item
            key="6e"
            onClick={() => navigate("/kyc-unverified-agents")}
          >
            KYC Unverified
          </Menu.Item>
          <Menu.Item key="6f" onClick={() => navigate("/kyc-pending-agents")}>
            KYC Pending
          </Menu.Item>
          <Menu.Item key="6g" onClick={() => navigate("/with-balance-agents")}>
            With Balance
          </Menu.Item>
          <Menu.Item key="6h" onClick={() => navigate("/all-agents")}>
            All Agents
          </Menu.Item>
          {/* <Menu.Item
            key="6i"
            onClick={() => navigate("/notification-all-agents")}
          >
            Notification to All
          </Menu.Item> */}
        </SubMenu>
        {/* merchants */}
        <SubMenu
          key="7"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={MerchantIcon} alt="" />
            </Space>
          }
          title="Merchants"
        >
          <Menu.Item key="7a" onClick={() => navigate("/active-merchants")}>
            Active Merchants
          </Menu.Item>
          <Menu.Item key="7b" onClick={() => navigate("/banned-merchants")}>
            Banned Merchants
          </Menu.Item>
          <Menu.Item
            key="7c"
            onClick={() => navigate("/email-unverified-merchants")}
          >
            Email Unverified
          </Menu.Item>
          <Menu.Item
            key="7d"
            onClick={() => navigate("/mobile-unverified-merchants")}
          >
            Mobile Unverified
          </Menu.Item>
          <Menu.Item
            key="7e"
            onClick={() => navigate("/kyc-unverified-merchants")}
          >
            KYC Unverified
          </Menu.Item>
          <Menu.Item
            key="7f"
            onClick={() => navigate("/kyc-pending-merchants")}
          >
            KYC Pending
          </Menu.Item>
          <Menu.Item
            key="7g"
            onClick={() => navigate("/with-balance-merchants")}
          >
            With Balance
          </Menu.Item>
          <Menu.Item key="7h" onClick={() => navigate("/all-Merchants")}>
            All Merchants
          </Menu.Item>
          {/* <Menu.Item
            key="7i"
            onClick={() => navigate("/notification-all-Merchants")}
          >
            Notification to All
          </Menu.Item> */}
        </SubMenu>
        {/* kyc */}

        {/* <div className="menu-heading">Manage KYC</div>
        <SubMenu
          key="8"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={KycIcon} alt="" />
            </Space>
          }
          title="KYC Setting"
        >
          <Menu.Item key="8a" onClick={() => navigate("/user-kyc")}>
            User KYC
          </Menu.Item>
          <Menu.Item key="8b" onClick={() => navigate("/agent-kyc")}>
            Agent KYC
          </Menu.Item>
          <Menu.Item key="8c" onClick={() => navigate("/merchant-kyc")}>
            Merchant KYC
          </Menu.Item>
        </SubMenu> */}
        {/* payment gateway */}
        <div className="menu-heading">Payment and Others</div>
        <SubMenu
          key="9"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={PaymentIcon} alt="" />
            </Space>
          }
          title="Payments Gateway"
        >
          <Menu.Item key="9a" onClick={() => navigate("/payment-gateways")}>
            Automatic Gateways
          </Menu.Item>
          <Menu.Item key="9b" onClick={() => navigate("/manual-gateways")}>
            Manual Gateways
          </Menu.Item>
        </SubMenu>
        {/* Deposits */}
        <SubMenu
          key="10"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={DepositsIcon} alt="" />
            </Space>
          }
          title="Deposits"
        >
          <Menu.Item key="10a" onClick={() => navigate("/pending-deposits")}>
            Pending Deposits
          </Menu.Item>
          <Menu.Item key="10b" onClick={() => navigate("/approved-deposits")}>
            Approved Deposits
          </Menu.Item>
          <Menu.Item key="10c" onClick={() => navigate("/successful-deposits")}>
            Successful Deposits
          </Menu.Item>
          <Menu.Item key="10d" onClick={() => navigate("/rejected-deposits")}>
            Rejected Deposits
          </Menu.Item>
          <Menu.Item key="10e" onClick={() => navigate("/initiated-deposits")}>
            Initiated Deposits
          </Menu.Item>
          <Menu.Item key="10f" onClick={() => navigate("/all-deposits")}>
            All Deposits
          </Menu.Item>
        </SubMenu>

        {/* Withdraws */}
        <SubMenu
          key="11"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={WithdrawIcon} alt="" />
            </Space>
          }
          title="Withdraws"
        >
          <Menu.Item key="11a" onClick={() => navigate("/withdraw-methods")}>
            Withdrawl Methods
          </Menu.Item>
          <Menu.Item key="11b" onClick={() => navigate("/pending-withdraws")}>
            Pending Withdraws
          </Menu.Item>
          <Menu.Item key="11c" onClick={() => navigate("/approved-withdraws")}>
            Approved Withdraws
          </Menu.Item>

          <Menu.Item key="11d" onClick={() => navigate("/rejected-withdraws")}>
            Rejected Withdraws
          </Menu.Item>

          <Menu.Item key="11f" onClick={() => navigate("/all-withdraws")}>
            All Withdraws
          </Menu.Item>
        </SubMenu>
        {/* Tickets */}
        <SubMenu
          key="12"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={TicketIcon} alt="" />
            </Space>
          }
          title="Support Ticket"
        >
          <Menu.Item key="12a" onClick={() => navigate("/pending-ticket")}>
            Pending Ticket
          </Menu.Item>
          <Menu.Item key="12b" onClick={() => navigate("/closed-ticket")}>
            Closed Ticket
          </Menu.Item>
          <Menu.Item key="12c" onClick={() => navigate("/answered-ticket")}>
            Answered Ticket
          </Menu.Item>
          <Menu.Item key="12d" onClick={() => navigate("/all-ticket")}>
            All Ticket
          </Menu.Item>
        </SubMenu>
        {/* Reports */}
        <SubMenu
          key="13"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={ReportIcon} alt="" />
            </Space>
          }
          title=" Report"
        >
          <Menu.Item key="13a" onClick={() => navigate("/transaction-log")}>
            Transaction Log
          </Menu.Item>
          <Menu.Item key="13b" onClick={() => navigate("/login-history")}>
            Login History
          </Menu.Item>
          <Menu.Item
            key="13c"
            onClick={() => navigate("/notification-history")}
          >
            Notification History
          </Menu.Item>
        </SubMenu>
        {/*  */}
        <Menu.Item
          key="14"
          onClick={() => navigate("/subscriber-managers")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={SubscribeIcon} alt="" />
            </Space>
          }
        >
          Subscribers
        </Menu.Item>
        {/*  */}
        <div className="menu-heading">Settings</div>
        <Menu.Item
          key="15"
          onClick={() => navigate("/general-settings")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={SettingIcon} alt="" />
            </Space>
          }
        >
          General Setting
        </Menu.Item>
        {/*  */}
        <Menu.Item
          key="16"
          onClick={() => navigate("/system-configuration")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={ConfigIcon} alt="" />
            </Space>
          }
        >
          System Configuration
        </Menu.Item>
        {/*  */}
        <Menu.Item
          key="17"
          onClick={() => navigate("/module-settings")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={ModuleIcon} alt="" />
            </Space>
          }
        >
          Module Setting
        </Menu.Item>
        {/*  */}
        <Menu.Item
          key="17a"
          onClick={() => navigate("/qr-code-template")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={QrIcon} alt="" />
            </Space>
          }
        >
          QR code template
        </Menu.Item>
        {/* Logo */}
        <Menu.Item
          key="18"
          onClick={() => navigate("/logo-favicon")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={LogoIcon} alt="" />
            </Space>
          }
        >
          Logo and Favicon
        </Menu.Item>
        {/* extensions */}
        {/* <Menu.Item
          key="19"
          onClick={() => navigate("/extensions")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={ExtensionIcon} alt="" />
            </Space>
          }
        >
          Extensions
        </Menu.Item> */}
        {/*  */}
        <Menu.Item
          key="20"
          onClick={() => navigate("/language")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={LanguageIcon} alt="" />
            </Space>
          }
        >
          Language
        </Menu.Item>
        <Menu.Item
          key="21"
          onClick={() => navigate("/seo-manager")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={SeoIcon} alt="" />
            </Space>
          }
        >
          SEO manager
        </Menu.Item>
        {/*  */}

        {/* <Menu.Item
          key="22"
          onClick={() => navigate("/notification-settings")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={NotificationIcon} alt="" />
            </Space>
          }
        >
          Notification Settings
        </Menu.Item> */}
        <SubMenu
          key="22"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={NotificationIcon} alt="" />
            </Space>
          }
          title="Notification Settings"
        >
          <Menu.Item key="22a" onClick={() => navigate("/all-notification-template")}>
            Global Template
          </Menu.Item>
          {/*  */}
          {/* <Menu.Item
            key="22b"
            onClick={() => navigate("/all-notification-template")}
          >
            Notification Template
          </Menu.Item> */}
          <Menu.Item key="22c" onClick={() => navigate("/sms-settings")}>
            Sms Settings
          </Menu.Item>
          <Menu.Item key="22f" onClick={() => navigate("/email-settings")}>
            Email Settings
          </Menu.Item>
          <Menu.Item key="22d" onClick={() => navigate("/push-notification-settings")}>
            Push Settings
          </Menu.Item>
          <Menu.Item
            key="22e"
            onClick={() => navigate("/notification-template")}
          >
            Notification Template
          </Menu.Item>
        </SubMenu>

        {/*  */}
        <div className="menu-heading">Frontend Manager</div>
        {/* <Menu.Item
          key="23"
          onClick={() => navigate("/manage-template")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={TemplateIcon} alt="" />
            </Space>
          }
        >
          Manage template
        </Menu.Item> */}
        <Menu.Item
          key="24"
          onClick={() => navigate("/manage-pages")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={PagesIcon} alt="" />
            </Space>
          }
        >
          Manage Pages
        </Menu.Item>
        {/*  */}
        <SubMenu
          key="25"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={SectionIcon} alt="" />
            </Space>
          }
          title="Manage Section"
        >
          <Menu.Item key="25a" onClick={() => navigate("/about-us")}>
            About Us
          </Menu.Item>
          {/*  */}
          {/* <Menu.Item
            key="25ab"
            onClick={() => navigate("/all-notification-template")}
          >
            All Notification Template
          </Menu.Item>
          <Menu.Item
            key="25abc"
            onClick={() => navigate("/sms-notification-settings")}
          >
            Sms Notification Settings
          </Menu.Item>
          <Menu.Item
            key="25abcd"
            onClick={() => navigate("/push-notification-settings")}
          >
            Push Notification Settings
          </Menu.Item>
          <Menu.Item
            key="25abcde"
            onClick={() => navigate("/notification-template")}
          >
            Notification Template
          </Menu.Item>
          <Menu.Item
            key="25abcdef"
            onClick={() => navigate("/all-global-notification-template")}
          >
            All Global Notification Template
          </Menu.Item> */}
          <Menu.Item
            key="25abcdefg"
            onClick={() => navigate("/logout-from-devices")}
          >
            Logout From Other Devices
          </Menu.Item>
          {/*  */}
          <Menu.Item key="25b" onClick={() => navigate("/agent-login")}>
            Agent Login and Reg
          </Menu.Item>
          <Menu.Item key="25c" onClick={() => navigate("/banner-section")}>
            Banner section
          </Menu.Item>
          <Menu.Item key="25d" onClick={() => navigate("/blog-section")}>
            Blog section
          </Menu.Item>
          <Menu.Item key="25e" onClick={() => navigate("/brand-section")}>
            Brand section
          </Menu.Item>
          <Menu.Item key="25f" onClick={() => navigate("/breadcrumb")}>
            Breadcrumb
          </Menu.Item>
          <Menu.Item key="25g" onClick={() => navigate("/business-section")}>
            Business Section
          </Menu.Item>
          <Menu.Item key="25h" onClick={() => navigate("/contact-section")}>
            Contact Us
          </Menu.Item>
          <Menu.Item key="25i" onClick={() => navigate("/feature-section")}>
            Feature Section
          </Menu.Item>
          <Menu.Item key="25j" onClick={() => navigate("/footer-section")}>
            Footer Section
          </Menu.Item>
          <Menu.Item key="25k" onClick={() => navigate("/user-login-section")}>
            User Login & Reg
          </Menu.Item>
          <Menu.Item
            key="25l"
            onClick={() => navigate("/merchant-login-section")}
          >
            Merchant Login and Reg
          </Menu.Item>
          <Menu.Item key="25m" onClick={() => navigate("/overview-section")}>
            Overview Section
          </Menu.Item>
          <Menu.Item key="25n" onClick={() => navigate("/policy-pages")}>
            Policy Pages
          </Menu.Item>
          <Menu.Item key="25o" onClick={() => navigate("/service-section")}>
            Service Section
          </Menu.Item>
          <Menu.Item key="25p" onClick={() => navigate("/sleep-mode")}>
            Sleep Mode
          </Menu.Item>
          <Menu.Item key="25q" onClick={() => navigate("/socail-icons")}>
            Social Icons
          </Menu.Item>
          <Menu.Item key="25r" onClick={() => navigate("/subscribe-section")}>
            Subscribe Section
          </Menu.Item>
          <Menu.Item key="25s" onClick={() => navigate("/testimonial-section")}>
            Testimonial Section
          </Menu.Item>
          <Menu.Item key="25t" onClick={() => navigate("/choose-us-section")}>
            Why Choose Us
          </Menu.Item>
        </SubMenu>

        <div className="menu-heading">Extra</div>
        <Menu.Item
          key="26"
          onClick={() => navigate("/plugins")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={ApiIcon} alt="" />
            </Space>
          }
        >
          API Plugins
        </Menu.Item>
        <Menu.Item
          key="27"
          onClick={() => navigate("/maintainance-mode")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={MaintainaceIcon} alt="" />
            </Space>
          }
        >
          Maintenance Mode
        </Menu.Item>
        <Menu.Item
          key="28"
          onClick={() => navigate("/gdpr-cookie")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={GdprIcon} alt="" />
            </Space>
          }
        >
          GDPR Cookies
        </Menu.Item>
        {/* System */}
        <SubMenu
          key="29"
          icon={
            <Space direction="horizontal" size={4} align="center">
              <img src={ArrowDownIcon} alt="#" />
              <img src={SystemIcon} alt="" />
            </Space>
          }
          title="System"
        >
          <Menu.Item key="29a" onClick={() => navigate("/system/application")}>
            Application
          </Menu.Item>
          <Menu.Item key="29b" onClick={() => navigate("/system/server")}>
            Server
          </Menu.Item>
          <Menu.Item key="29c" onClick={() => navigate("/system/cache")}>
            Cache
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key="30"
          onClick={() => navigate("/custom-css")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={CustomIcon} alt="" />
            </Space>
          }
        >
          Custom CSS
        </Menu.Item>
        <Menu.Item
          key="31"
          onClick={() => navigate("/listed-reports")}
          icon={
            <Space direction="horizontal" size={20} align="center">
              <div />
              <img src={RequestIcon} alt="" />
            </Space>
          }
        >
          Report and Request
        </Menu.Item>
        <br />
        <br />
        <br />
        <br />
      </Menu>
    </Sider>
  );
};

export default SiderBar;
