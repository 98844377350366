import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Report {
  getLoginHistory = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/login-histories?type=${
          payload?.type || ""
        }&search=${payload?.search || ""}`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getTransactionLog = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/transactions?user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }&trx_type=${payload?.trx_type || ""}&remark=${payload?.remark || ""}`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getNotificationHistory = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/notification-logs?user_type=${
          payload?.user_type || ""
        }&search=${payload?.search || ""}`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  // user detail login history
  getUserLoginHistory = (user_id, payload) => {
    return axios
      .get(`${API_URL}api/v1/admin/login-histories/${user_id}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  // user login as user
  getUsersLoginHistory = (user_id, payload) => {
    return axios
      .get(`${API_URL}api/v1/admin/users/${user_id}/login-token`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getUserNotificationHistory = (user_id) => {
    return axios
      .get(`${API_URL}api/v1/admin/notification-logs?search=${user_id || ""}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
