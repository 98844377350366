import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Deposits {
  getAllDeposits = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/diposits?user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,

        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getSuccessfulDeposits = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/diposits?status=${"Successful"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getPendingDeposits = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/diposits?status=${"Pending"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getRejectedDeposits = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/diposits?status=${"Rejected"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getApprovedDeposits = (payload={}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/diposits?status=${"Approved"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getInitiatedDeposits = (payload) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/diposits?status=${"Initiated"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getSuccessfulDepositAmount = () => {
    return axios
      .get(`${API_URL}api/v1/admin/diposits/total-successfull-diposit-amount`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getTotalDepositCharge = () => {
    return axios
      .get(`${API_URL}api/v1/admin/diposits/total-pending-diposit-amount`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getTotalRejectedDeposit = () => {
    return axios
      .get(`${API_URL}api/v1/admin/diposits/total-rejected-diposit-amount`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getTotalInitiatedDeposit = () => {
    return axios
      .get(`${API_URL}api/v1/admin/diposits/total-initiated-diposit-amount`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getDepositDetail = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/diposits/${id}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
