import React from "react";
import { Input } from "antd";
import {
  SearchOutlined,CloseCircleOutlined
} from "@ant-design/icons";

import "./styles.scss";

const Search = ({ handleSearch }) => {
  return (
    <div className="search-field">
      
      <Input
        placeholder="Search..."
        allowClear
        prefix={<SearchOutlined className="search-icon" />}
        // suffix={<CloseCircleOutlined />}
        style={{ width: "200px" }}
        // onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};

export default Search;
