import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Agents {
  getAllAgents = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/agents?search=${payload?.search || ""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getActiveAgents = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/agents?active=true&search=${
          payload?.search || ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getBannedAgents = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/agents?is_banned=true&search=${
          payload?.search || ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getEmailUnverifiedAgents = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/agents?limit=${
          payload?.payload?.limit || 10
        }&offset=${
          payload?.payload?.offset || 0
        }&is_email_verified=true&search=${payload?.search || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getmobileUnverifiedAgents = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/agents?limit=${
          payload?.payload?.limit || 10
        }&offset=${
          payload?.payload?.offset || 0
        }&is_mobile_verified=false&search=${payload?.search || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getKycUnverifiedAgents = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/kycs?limit=${
          payload?.payload?.limit || 10
        }&offset=${
          payload?.payload?.offset || 0
        }&type=agent&status=unverified&search=${payload?.search || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getKycPendingAgents = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/kycs?type=agent&status=pending&search=${
          payload?.search || ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getWithBalanceAgents = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/wallets/users-with-balance?limit=${
          payload?.payload?.limit || 10
        }&offset=${payload?.payload?.offset || 0}&user_type=agent&search=${
          payload?.search || ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
