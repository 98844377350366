import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class QRCode {
  getQRValue = () => {
    const url = `${API_URL}api/v1/agents/qr-codes`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  logoutAll = (payload) => {
    const url = `${API_URL}api/v1/users/auth/logout-all`;
    return axios
      .delete(url, {
        data:payload,
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
