import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Users {
  
  getAllUsersList = (payload) => {
    const url = `${API_URL}api/v1/admin/users/?search=${payload?.search||""}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getActiveUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/users?active=true&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getUserDetail = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/users/${id}/details`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  updateUser = (formData,id) => {
    const url = `${API_URL}api/v1/admin/users/${id}/details`;
    return axios({
      url,
      method: "PATCH", 
      data: formData,     
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // ban or unban a user
  banUser = (id) => {
    const url = `${API_URL}api/v1/admin/users/${id}/ban`;
    return axios({
      url,
      method: "PATCH",      
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  unbanUser = (id) => {
    const url = `${API_URL}api/v1/admin/users/${id}/unban`;
    return axios({
      url,
      method: "PATCH",      
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // add or subtract balance from a user
  addBalance = (formData, id) => {
    const url = `${API_URL}api/v1/admin/users/${id}/add-balance`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  subtractBalance = (formData, id) => {
    const url = `${API_URL}api/v1/admin/users/${id}/subtract-balance`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // login histories of a user
  getLoginHistoy = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/login-histories/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  // get user login token
  getUserLoginToken = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/users/${id}/login-token`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  // user total withdraws
  getUserTotalWithdraws = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/users/${id}/total-withdrawals`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getUserTotalTransactions = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/users/${id}/total-transactions`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getUserTotalMoneyOut = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/users/${id}/total-money-out`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getUserWallet= (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/wallets/users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  //Admin Users
  getAdminUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/admin-list?`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getAdminUsersDetails = (id) => {
    const url = `${API_URL}api/v1/admin/${id}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  addAdmin = (formData) => {
    const url = `${API_URL}api/v1/admin/admin-list`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateAdmin = (formData, id) => {
    const url = `${API_URL}api/v1/admin/${id}`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  deleteAdmin = (id) => {
    const url = `${API_URL}api/v1/admin/${id}`;
    return axios
      .delete(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  //
  getBannedUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/users?is_banned=true&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getEmailUnverifiedUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/users?is_email_verified=true&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getmobileUnverifiedUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/users?is_mobile_verified=false&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getKycUnverifiedUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/kycs?type=user&status=unverified&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getKycPendingUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/kycs?type=user&status=pending&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  // api/v1/admin/wallets/users-with-balance?page=1&limit=3&user_type=merchant&search=manjumiqbal66@gmail.com
  getWithBalanceUsers = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/wallets/users-with-balance?user_type=user&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
// Merchants
  getAllMerchantsList = (payload) => {
    const url = `${API_URL}api/v1/admin/merchants/?search=${payload?.search||""}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  getActiveMerchants = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/merchants?active=true&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getBannedMerchants = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/merchants?is_banned=true&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getEmailUnverifiedMerchants = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/merchants?page=1&limit=2&is_email_verified=true&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getmobileUnverifiedMerchants = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/merchants?is_mobile_verified=false&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getKycUnverifiedMerchants = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/kycs?type=merchant&status=unverified&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getKycPendingMerchants = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/kycs?type=merchant&status=pending&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getWithBalanceMerchants = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/wallets/users-with-balance?user_type=merchant&search=${payload?.search||""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  //
}
