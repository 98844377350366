import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Space, message } from "antd";
import { useSelector } from "react-redux";
import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";
import Spinner from "../../common/spinner";
import clockImg from "../../../images/iconamoon_clock-thin.svg";
import cancelImg from "../../../images/cancelModal.svg";
import "./styles.scss";

const NotificationModal = ({ setIsModalOpen }) => {
  const notification = new API.Notifications();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [latestUnreadNotifications, setLatestUnreadNotifications] = useState();

  useEffect(() => {
    fetchLatestNotifications();
  }, []);

  const { unreadNotifications } = useSelector((state) => {   
    return state?.app;
  });

  const currentDate = new Date();

  const getTimeDifference=(currentDate, createdAtDate)=> {
    const timeDifference = Math.floor((currentDate - createdAtDate) / 60000); // Difference in minutes
    if (timeDifference < 1) {
      return 'Just now';
    } else if (timeDifference === 1) {
      return '1 minute ago';
    } else if (timeDifference < 60) {
      return `${timeDifference} minutes ago`;
    } else {
      const hoursDifference = Math.floor(timeDifference / 60);
      if (hoursDifference === 1) {
        return '1 hour ago';
      } else if (hoursDifference < 24) {
        return `${hoursDifference} hours ago`;
      } else {
        const daysDifference = Math.floor(hoursDifference / 24);
        if (daysDifference === 1) {
          return '1 day ago';
        } else {
          return `${daysDifference} days ago`;
        }
      }
    }
  }

  const fetchLatestNotifications = () => {
    getDataManager(notification?.getLatestUnreadNotifications, setLoading).then(
      (x) => {
        if (x?.status === true) {
          setLatestUnreadNotifications(x?.data);
        } else {
          const error = getErrorMessage(x?.errors);
          message.error({
            content: x?.message || error || "Error ocurred",
            duration: 3,
          });
        }
      }
    );
  };

  const handleAllNotifications = () => {
    setIsModalOpen(false);
    navigate("/notification-settings");
  };

  return (
    <section className="notification-modal-container">
       
      <div className="currency-modal">        
      {loading && <Spinner/>}
        <section className="section-heading">
          <div className="modal-title">Notification</div>
          <img src={cancelImg} alt="#" className="cancel-img" onClick={()=>setIsModalOpen(false)}/>
        </section>
        <div className="unread-notification-text">
            You have {unreadNotifications?.count} Unread notifications
          </div>
        {latestUnreadNotifications?.map((e) => {
          const createdAtDate = new Date(e?.createdAt);
          return (
            <section className="all-notifications">
              <div className="notification-title">{e?.message}</div>
              <Space direction="horizontal" align="baseline">
                <img src={clockImg} alt="#" />
                <div className="time-text">{getTimeDifference(currentDate, createdAtDate)}</div>
              </Space>
            </section>
          );
        })}
        <div
          className="view-all-notifications"
          onClick={() => handleAllNotifications()}
        >
          View All Notifications
        </div>
      </div>
      <div className="modal-mask" />
    </section>
  );
};

export default NotificationModal;
