import Auth from "./Auth";
import Kyc from "./Kyc";
import Subscribers from "./Subscribers";
import Currency from "./Currency";
import Paymentgateways from "./Paymentgateways";
import Transactions from "./Transactions";
import Tickets from "./Tickets";
import Agents from "./Agents";
import Withdraws from "./Withdraws";
import Users from "./Users";
import Info from "./Info";
import Profile from "./Profile";
import Report from "./Reports";
import Profit from "./Profit";
import Deposits from "./Deposits";
import Dashboard from "./Dashboard";
import Testimonials from "./Testimonials";
import Brands from "./Brands";
import Policies from "./Policy";
import AboutUS from "./AboutUs";
import Banner from "./Banner";
import Notifications from "./Notifications";
import SEO from "./Seo";
import Extra from "./Extra";
import Support from "./Support";
import QRCode from "./QRcode";
import Business from "./Business";
import Features from "./Features";
import Blogs from "./Blogs";
const API = {
  Auth,
  Subscribers,
  Currency,
  
  //
  Profile,
  Report,
  Profit,
  Deposits,
  Users,
  Agents,
  Tickets,
  Transactions,
  Kyc,
  Withdraws,
  Paymentgateways,
  Info,
  Dashboard,
  Testimonials,
  Brands,
  Policies,
  AboutUS,
  Banner,
  Notifications,
  SEO,
  Extra,
  Support,
  QRCode,
  Business,
  Features,
  Blogs
};

export default API;
