import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Transactions {
  updateMoneyTransferData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/money-transfer`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateVoucherChargeData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/voucher-charge`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateInvoiceChargeData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/invoice-charge`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateMoneyExchangeData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/exchange-charge`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateMoneyOutData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/money-out-charge`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateMoneyInData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/money-in-charge`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateApiPaymentData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/api-charge`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateVoucherPaymentData = (payload) => {
    const url = `${API_URL}api/v1/admin/transaction-charges/make_payment`;
    return axios({
      url,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getTransactions = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/transaction-charges`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
