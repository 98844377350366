import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Profit {
  getAllProfits = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/charge-logs?search=${
          payload?.selectedCurrency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getTotalProfit = () => {
    return axios
      .get(`${API_URL}api/v1/admin/charge-logs/total-profit`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getOnlyProfits = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/charge-logs/profits?search=${
          payload?.selectedCurrency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getOnlyCommission = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/charge-logs/commissions?search=${
          payload?.selectedCurrency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
