import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Info {
  getPages = () => {
    return axios
      .get(`${API_URL}api/v1/admin/frontend-pages`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getPageDetails = (id) => {
    const url = `${API_URL}api/v1/admin/frontend-pages/${id}/`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  addPage = (formData) => {
    const url = `${API_URL}api/v1/admin/frontend-pages/`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updatePage = (formData,id) => {
    const url = `${API_URL}api/v1/admin/frontend-pages/${id}/`;
    return axios({
      url,
      method: "PUT",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  deletePage = (id) => {
    const url = `${API_URL}api/v1/admin/frontend-pages/${id}`;
    return axios
      .delete(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getSleepMode = () => {
    return axios
      .get(`${API_URL}api/v1/admin/sleep-modes`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getModuleSettings = () => {
    return axios
      .get(`${API_URL}api/v1/admin/module-permissions`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  updateModuleSettings = (formData,id) => {
    const url = `${API_URL}api/v1/admin/module-permissions/${id}`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  //Qr code section
  getQrTemplate = () => {
    return axios
      .get(`${API_URL}api/v1/admin/qr-code-templates`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  updateQrTemplate = (formData,id) => {
    const url = `${API_URL}api/v1/admin/qr-code-templates/${id}`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // contact us section
  getContactUs = () => {
    return axios
      .get(`${API_URL}api/v1/admin/contact-us`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  updateContactUs = (formData,id) => {
    const url = `${API_URL}api/v1/admin/contact-us/${id}`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // 
  getBreadCrumbs = () => {
    return axios
      .get(`${API_URL}api/v1/admin/breadcrumb`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  updateBreadCrumbs = (formData,id) => {
    const url = `${API_URL}api/v1/admin/breadcrumb/${id}`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
