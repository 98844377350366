import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Paymentgateways {
  getAutomaticGateways = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/automatic-gateways?search=${
          payload?.search || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getAutomaticGatewayDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/automatic-gateways/${id}/details`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getAutomaticGatewayCurrency = (id) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/automatic-gateways/${id}/gateway-currencies`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  disableGateway = (id) => {
    const url = `${API_URL}api/v1/admin/automatic-gateways/${id}/disable`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  enableGateway = (id) => {
    const url = `${API_URL}api/v1/admin/automatic-gateways/${id}/enable`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateAutomaticGateway = (payload) => {
    const url = `${API_URL}api/v1/admin/automatic-gateways/stripe`;
    return axios({
      url,
      method: "PATCH",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // manualgateway
  getManualGateways = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/manual-gateways?search=${
          payload?.search || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  addManualGateway = (formData) => {
    const url = `${API_URL}api/v1/admin/manual-gateways`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getManualGatewayDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/manual-gateways/${id}/details`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  updateManualGateway = (formData, id) => {
    const url = `${API_URL}api/v1/admin/manual-gateways/${id}`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // currencies
  getCurrency = () => {
    return axios
      .get(`${API_URL}api/v1/common/currencies`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  disableManualgGateway = (id) => {
    const url = `${API_URL}api/v1/admin/manual-gateways/${id}/disable`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  enableManualGateway = (id) => {
    const url = `${API_URL}api/v1/admin/manual-gateways/${id}/enable`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
