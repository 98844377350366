/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from "react";
import { Layout, Space, Avatar, Popover } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AvatarImg from "../../images/avatar.png";
import notificationImg from "../../images/notification.png";
import Spinner from "../common/spinner";
import SiderBar from "./sidebar";
import Search from "../common/search";
import NotificationModal from "../NotificationSettings/NotificationModal";
import { setUserData } from "../../utils/redux/actions";
import passwordIcon from "../../images/password-icon.png";
import profileIcon from "../../images/profile-icon.png";
import faIcon from "../../images/2faIcon.png";
import kycIcon from "../../images/kycicon.png";
import qrcodeIcon from "../../images/QRicon.png";
import logoutAllIcon from "../../images/logouticon.png";
import logoutIcon from "../../images/Logoutarrow.png";
import "./styles.scss";

const Dashboard = React.lazy(() => import("../Dashboard"));
const ManageCurrencies = React.lazy(() => import("../ManageCurrencies"));
const TransactionCharges = React.lazy(() => import("../TransactionCharges"));
//profit logs
const ProfitAllLogs = React.lazy(() => import("../ProfitLogs"));
const OnlyProfit = React.lazy(() => import("../ProfitLogs/OnlyProfit"));
const OnlyCommission = React.lazy(() => import("../ProfitLogs/OnlyCommission"));
//User section
const AdminUsers = React.lazy(() => import("../Users/AdminUsers"));
const AddAdminUsers = React.lazy(() =>
  import("../Users/AdminUsers/AddAdminUser")
);
const AdminUserDetail = React.lazy(() => import("../Users/AdminUserDetails"));
const ActiveUsers = React.lazy(() => import("../Users/ActiveUsers"));
const BannedUsers = React.lazy(() => import("../Users/BannedUsers"));
const EmailUnverifiedUsers = React.lazy(() =>
  import("../Users/EmailUnverified")
);
const KycUnverifiedUsers = React.lazy(() => import("../Users/KycUnverified"));
const MobileUnverifiedUsers = React.lazy(() =>
  import("../Users/MobileUnverified")
);
const KycPendingUsers = React.lazy(() => import("../Users/KycPending"));
const UsersWithBalance = React.lazy(() => import("../Users/WithBalance"));
const AllUsers = React.lazy(() => import("../Users/AllUsers"));
const UserDetails = React.lazy(() => import("../Users/UserDetails"));
const UserDetailLoginHistory = React.lazy(() =>
  import("../Users/UserDetails/userLoginHistory")
);
const UsersDetailLoginHistory = React.lazy(() =>
  import("../Users/UserDetails/usersLoginHistory")
);
const UserNotificationHistory = React.lazy(() =>
  import("../Users/UserDetails/userNotificationHistory")
);
const NotificationAllUsers = React.lazy(() =>
  import("../Users/AllNotificarion")
);
//Agents section
const ActiveAgents = React.lazy(() => import("../Agents/ActiveAgents"));
const BannedAgents = React.lazy(() => import("../Agents/BannedAgents"));
const EmailUnverifiedAgents = React.lazy(() =>
  import("../Agents/EmailUnverified")
);
const KycUnverifiedAgents = React.lazy(() => import("../Agents/KycUnverified"));
const MobileUnverifiedAgents = React.lazy(() =>
  import("../Agents/MobileUnverified")
);
const KycPendingAgents = React.lazy(() => import("../Agents/KycPending"));
const AgentsWithBalance = React.lazy(() => import("../Agents/WithBalance"));
const AllAgents = React.lazy(() => import("../Agents/AllAgents"));
//Merchants section
const ActiveMerchants = React.lazy(() =>
  import("../Merchants/ActiveMerchants")
);
const BannedMerchants = React.lazy(() =>
  import("../Merchants/BannedMerchants")
);
const EmailUnverifiedMerchants = React.lazy(() =>
  import("../Merchants/EmailUnverified")
);
const KycUnverifiedMerchants = React.lazy(() =>
  import("../Merchants/KycUnverified")
);
const MobileUnverifiedMerchants = React.lazy(() =>
  import("../Merchants/MobileUnverified")
);
const KycPendingMerchants = React.lazy(() => import("../Merchants/KycPending"));
const MerchantsWithBalance = React.lazy(() =>
  import("../Merchants/WithBalance")
);
const AllMerchants = React.lazy(() => import("../Merchants/AllMerchants"));
//kyc settings section
const UserKyc = React.lazy(() => import("../Kyc/UserKyc"));
const AgentKyc = React.lazy(() => import("../Kyc/AgentKyc"));
const MerchantKyc = React.lazy(() => import("../Kyc/MerchantKyc"));
const KycDetails = React.lazy(() => import("../common/kycDetails"));
//payment gateways
const PaymentGateway = React.lazy(() => import("../PaymentGateways"));
const ManualPaymentGateway = React.lazy(() =>
  import("../PaymentGateways/ManualPayments")
);
const AddManualGateway = React.lazy(() =>
  import("../PaymentGateways/ManualPayments/AddManualGateway")
);
const UpdateAutomaticGateway = React.lazy(() =>
  import("../PaymentGateways/UpdateAutomaticGateway")
);
// deposits
const PendingDeposits = React.lazy(() =>
  import("../Deposits/PendingDesposits")
);
const ApprovedDeposits = React.lazy(() =>
  import("../Deposits/ApprovedDeposits")
);
const SuccessfulDeposits = React.lazy(() =>
  import("../Deposits/SuccessfulDeposits")
);
const RejectedDeposits = React.lazy(() =>
  import("../Deposits/RejectedDeposits")
);
const InitiatedDeposits = React.lazy(() =>
  import("../Deposits/InitiatedDeposits")
);
const AllDeposits = React.lazy(() => import("../Deposits/AllDeposits"));
const DepositDetails = React.lazy(() =>
  import("../Deposits/AllDeposits/DepositDetails")
);

// Withdraws
const PendingWithdraws = React.lazy(() =>
  import("../Withdraws/PendingWithdraws")
);
const ApprovedWithdraws = React.lazy(() =>
  import("../Withdraws/ApprovedWithdraws")
);
const WithdrawMethods = React.lazy(() =>
  import("../Withdraws/WithdrawlMethods")
);
const AddNewMethod = React.lazy(() =>
  import("../Withdraws/WithdrawlMethods/addNewMethod")
);
const WithdrawMethodDetail = React.lazy(() =>
  import("../Withdraws/WithdrawlMethods/methodDetails")
);
const RejectedWithdraws = React.lazy(() =>
  import("../Withdraws/RejectedWithdraws")
);
const AllWithdraws = React.lazy(() => import("../Withdraws/AllDeposits"));
// Tickets
const PendingTicket = React.lazy(() => import("../Tickets/PendingTickets"));
const ClosedTicket = React.lazy(() => import("../Tickets/ClosedTickets"));
const AnsweredTicket = React.lazy(() => import("../Tickets/AnsweredTickets"));
const AllTicket = React.lazy(() => import("../Tickets/AllTickets"));
const TicketDetail = React.lazy(() => import("../Tickets/TicketDetail"));
// Reports
const TransactionLogs = React.lazy(() => import("../Reports/TransactionLogs"));
const LoginHistory = React.lazy(() => import("../Reports/LoginHistory"));
const NotificationHistory = React.lazy(() =>
  import("../Reports/NotificationHistory")
);
//Subscriber
const Subscribers = React.lazy(() => import("../Subscribers"));
const SendEmail = React.lazy(() => import("../Subscribers/SendEmail"));
// Settings
const GeneralSettings = React.lazy(() => import("../GeneralSettings"));
//
const SystemConfigurationAll = React.lazy(() =>
  import("../SystemConfiguration")
);
// Module
const ModuleSettings = React.lazy(() => import("../ModulesSettings"));
//Qrcode
const QrCodeTemplate = React.lazy(() => import("../QrCodeTemplate"));
//Logo-favicon
const LogoFavIconSettings = React.lazy(() => import("../LogoFavicon"));
//Extensions
const Extensions = React.lazy(() => import("../Extensions"));
// language
const LanguageManager = React.lazy(() => import("../LanguageManager"));
// seo
const SeoConfiguration = React.lazy(() => import("../SeoConfiguration"));
// notification settings
const NotificationSettings = React.lazy(() =>
  import("../NotificationSettings")
);
const EmailNotificationSettings = React.lazy(() =>
  import("../NotificationSettings/EmailNotificationSetting")
);
const AllNotificationTemplate = React.lazy(() =>
  import("../FrontendManager/SectionManager/AllNotificationTemplate")
);
const SmsNotificationSettings = React.lazy(() =>
  import("../FrontendManager/SectionManager/SmsNotificationSetting")
);
const PushNotificationSettings = React.lazy(() =>
  import("../FrontendManager/SectionManager/PushNotificationSetting")
);
const NotificationTemplate = React.lazy(() =>
  import("../FrontendManager/SectionManager/NotificationTemplate")
);
const AllGlobalNotificationTemplate = React.lazy(() =>
  import("../FrontendManager/SectionManager/AllGlobalNotificationTemplate")
);
// Frontend Manager
const ManageTemplates = React.lazy(() =>
  import("../FrontendManager/ManageTemplate")
);
const PagesManager = React.lazy(() =>
  import("../FrontendManager/PagesManager")
);
// Manage Section
const AboutUs = React.lazy(() =>
  import("../FrontendManager/SectionManager/AboutUs")
);
const AgentLogin = React.lazy(() =>
  import("../FrontendManager/SectionManager/AgentLogin")
);
const BannerSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/BannerSection")
);
const BlogSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/BlogSection")
);
const BrandSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/BrandSection")
);
const BreadCrumbSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/BreadCrumbSection")
);
const BusinessSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/BusinessSection")
);
const ContactSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/ContactSection")
);
const FeatureSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/FeatureSection")
);
const FooterSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/FooterSection")
);
const UserLoginSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/UserLoginSection")
);
const MerchantLoginSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/MerchantLoginSection")
);
const OverViewSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/OverViewSection")
);
const PolicyPages = React.lazy(() =>
  import("../FrontendManager/SectionManager/PolicyPages")
);
const ServiceSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/ServiceSection")
);
const SleepMode = React.lazy(() =>
  import("../FrontendManager/SectionManager/SleepMode")
);
const SocialIcons = React.lazy(() =>
  import("../FrontendManager/SectionManager/SocialIcons")
);
const SubscribeSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/SubscribeSection")
);
const TestimonialSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/TestimonialSection")
);
const WhyChooseSection = React.lazy(() =>
  import("../FrontendManager/SectionManager/WhyChooseSection")
);

const LogoutFromAllDevices = React.lazy(() =>
  import("../FrontendManager/SectionManager/LogoutDevices")
);
// Extra
const ApiPlugins = React.lazy(() => import("../Extra/ApiPlugins"));
const MaintenanceMode = React.lazy(() => import("../Extra/MaintainanceMode"));
const GdprCookie = React.lazy(() => import("../Extra/GdprCookie"));
const ApplicationInformation = React.lazy(() =>
  import("../Extra/System/Application")
);
const ServerInformation = React.lazy(() => import("../Extra/System/Server"));
const CacheInformation = React.lazy(() => import("../Extra/System/Cache"));
const CustomCss = React.lazy(() => import("../Extra/CustomCss"));
const ListedReport = React.lazy(() => import("../Extra/ListedReport"));
// logout from all devices
const LogoutDevices = React.lazy(() => import("../LogoutDevices"));
//profile
const UserProfile = React.lazy(() => import("../UserProfile"));
const ChangePassword = React.lazy(() => import("../ChangePassword"));
//
const { Header, Content } = Layout;

const AdminLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const { unreadNotifications } = useSelector((state) => {
    return state?.app;
  });

  const { profileData } = useSelector((state) => {
    return state?.app;
  });

  const handleChangeLogout = (item) => {
    navigate(`/${item}`);
    setOpen(false);
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container">
        <div
          className="profile-opt"
          onClick={() => handleProfileClick("profile-setting")}
        >
          <img src={profileIcon} alt="#" />
          Profile
        </div>
        <div
          className="password-opt"
          onClick={() => handleChangeLogout("change-password")}
        >
          <img src={passwordIcon} alt="#" />
          Change Password
        </div>
        
        <div className="logout-opt" onClick={() => handleLogout("logout")}>
          <img src={logoutIcon} alt="#" />
          Logout
        </div>
      </section>
    );
  };

  const openMenu = () => {
    setOpen(!open);
  };

  const handleProfileClick = () => {
    navigate(`/profile-setting`);
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("wallet-admin-panel-user-data");
    dispatch(setUserData({}));
    navigate("/login");
    setOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <SiderBar toggle={toggle} collapsed={collapsed} />
      <Layout className="site-layout">
        <Header className="site-layout-background bugify-navbar">
          <Search />

          <Space align="center" size={20}>
            <Popover
              placement="bottom"
              content={userPopover()}
              trigger="click"
              className="user-popover"
              open={open}
            >
              <img
                src={notificationImg}
                alt="#"
                className="notification-img"
                onClick={() => showModal()}
              />
              <span className="count-text" onClick={() => showModal()}>
                {unreadNotifications?.count}
              </span>
              &nbsp;&nbsp; | &nbsp;&nbsp;
              <Avatar onClick={openMenu} src={profileData?.image} /> &nbsp;
              <span onClick={openMenu} className="admin-text">
                {profileData?.first_name} {profileData?.last_name}
              </span>
            </Popover>
          </Space>
        </Header>
        {isModalOpen && <NotificationModal setIsModalOpen={setIsModalOpen} />}
        <Content className="site-layout-background content-main-section">
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/manage-currencies" element={<ManageCurrencies />} />
              <Route
                path="/transaction-charges"
                element={<TransactionCharges />}
              />
              {/* profitlogs  */}
              <Route path="/all-logs" element={<ProfitAllLogs />} />
              <Route path="/only-profit" element={<OnlyProfit />} />
              <Route path="/only-commission" element={<OnlyCommission />} />
              {/* users */}
              <Route path="/admin-users" element={<AdminUsers />} />
              <Route path="/add-admin-user" element={<AddAdminUsers />} />
              <Route
                path="/admin-details/:adminId"
                element={<AdminUserDetail />}
              />
              <Route path="/active-users" element={<ActiveUsers />} />
              <Route path="/banned-users" element={<BannedUsers />} />
              <Route
                path="/email-unverified-users"
                element={<EmailUnverifiedUsers />}
              />
              <Route
                path="/mobile-unverified-users"
                element={<MobileUnverifiedUsers />}
              />
              <Route
                path="/kyc-unverified-users"
                element={<KycUnverifiedUsers />}
              />
              <Route path="/kyc-pending" element={<KycPendingUsers />} />
              <Route path="/with-balance" element={<UsersWithBalance />} />
              <Route path="/all-users" element={<AllUsers />} />
              <Route path="/user-details/:userId" element={<UserDetails />} />
              <Route
                path="/user-login-history/:userId"
                element={<UserDetailLoginHistory />}
              />
              <Route
                path="/users-login-history/:userId"
                element={<UsersDetailLoginHistory />}
              />
              <Route
                path="/user-notification-history/:userId"
                element={<UserNotificationHistory />}
              />
              <Route
                path="/notification-all"
                element={<NotificationAllUsers />}
              />
              {/* agents */}
              <Route path="/active-agents" element={<ActiveAgents />} />
              <Route path="/banned-agents" element={<BannedAgents />} />
              <Route
                path="/email-unverified-agents"
                element={<EmailUnverifiedAgents />}
              />
              <Route
                path="/mobile-unverified-agents"
                element={<MobileUnverifiedAgents />}
              />
              <Route
                path="/kyc-unverified-agents"
                element={<KycUnverifiedAgents />}
              />
              <Route
                path="/kyc-pending-agents"
                element={<KycPendingAgents />}
              />
              <Route
                path="/with-balance-agents"
                element={<AgentsWithBalance />}
              />
              <Route path="/all-agents" element={<AllAgents />} />
              {/* Merchants */}
              <Route path="/active-merchants" element={<ActiveMerchants />} />
              <Route path="/banned-merchants" element={<BannedMerchants />} />
              <Route
                path="/email-unverified-merchants"
                element={<EmailUnverifiedMerchants />}
              />
              <Route
                path="/mobile-unverified-merchants"
                element={<MobileUnverifiedMerchants />}
              />
              <Route
                path="/kyc-unverified-merchants"
                element={<KycUnverifiedMerchants />}
              />
              <Route
                path="/kyc-pending-merchants"
                element={<KycPendingMerchants />}
              />
              <Route
                path="/with-balance-merchants"
                element={<MerchantsWithBalance />}
              />
              <Route path="/all-merchants" element={<AllMerchants />} />
              {/* kyc setting */}
              <Route path="/user-kyc" element={<UserKyc />} />
              <Route path="/agent-kyc" element={<AgentKyc />} />
              <Route path="/merchant-kyc" element={<MerchantKyc />} />
              <Route path="/kyc/:kycId" element={<KycDetails />} />
              {/* payment gateways */}
              <Route path="/payment-gateways" element={<PaymentGateway />} />
              <Route
                path="/manual-gateways"
                element={<ManualPaymentGateway />}
              />
              <Route
                path="/add-manual-gateways"
                element={<AddManualGateway />}
              />
              <Route
                path="/update-manual-gateways/:gatewayId"
                element={<AddManualGateway />}
              />
              <Route
                path="/update-payment-gateway/:gatewayId"
                element={<UpdateAutomaticGateway />}
              />
              {/* Deposits */}
              <Route
                path="/successful-deposits"
                element={<SuccessfulDeposits />}
              />
              <Route path="/rejected-deposits" element={<RejectedDeposits />} />
              <Route
                path="/initiated-deposits"
                element={<InitiatedDeposits />}
              />
              <Route path="/all-deposits" element={<AllDeposits />} />
              <Route
                path="/deposit-detail/:depositId"
                element={<DepositDetails />}
              />
              <Route path="/pending-deposits" element={<PendingDeposits />} />
              <Route path="/approved-deposits" element={<ApprovedDeposits />} />
              {/* Withdraws */}
              <Route path="/withdraw-methods" element={<WithdrawMethods />} />
              <Route path="/withdraw-methods/:id" element={<WithdrawMethodDetail />} />
              <Route path="/add-new-method" element={<AddNewMethod />} />
              <Route path="/pending-withdraws" element={<PendingWithdraws />} />
              <Route
                path="/approved-withdraws"
                element={<ApprovedWithdraws />}
              />
              <Route
                path="/rejected-withdraws"
                element={<RejectedWithdraws />}
              />
              <Route path="/all-withdraws" element={<AllWithdraws />} />
              {/* Tickets */}
              <Route path="/pending-ticket" element={<PendingTicket />} />
              <Route path="/closed-ticket" element={<ClosedTicket />} />
              <Route path="/answered-ticket" element={<AnsweredTicket />} />
              <Route path="/all-ticket" element={<AllTicket />} />
              <Route
                path="/ticket-detail/:ticketId"
                element={<TicketDetail />}
              />
              {/* Reports */}
              <Route path="/transaction-log" element={<TransactionLogs />} />
              <Route path="/login-history" element={<LoginHistory />} />
              <Route
                path="/notification-history"
                element={<NotificationHistory />}
              />
              {/* subscribers */}
              <Route path="/subscriber-managers" element={<Subscribers />} />
              <Route path="/send-email" element={<SendEmail />} />
              {/* settings */}
              <Route path="/general-settings" element={<GeneralSettings />} />
              {/* Configuration */}
              <Route
                path="/system-configuration"
                element={<SystemConfigurationAll />}
              />
              {/* Modules */}
              <Route path="/module-settings" element={<ModuleSettings />} />
              {/* Qrcode */}
              <Route path="/qr-code-template" element={<QrCodeTemplate />} />
              {/* logo-favicon */}
              <Route path="/logo-favicon" element={<LogoFavIconSettings />} />
              {/* extensions */}
              <Route path="/extensions" element={<Extensions />} />
              {/* language */}
              <Route path="/language" element={<LanguageManager />} />
              {/* seo manager */}
              <Route path="/seo-manager" element={<SeoConfiguration />} />
              <Route
                path="/notification-settings"
                element={<NotificationSettings />}
              />
              {/* frontend manager */}
              <Route path="/manage-template" element={<ManageTemplates />} />
              <Route path="/manage-pages" element={<PagesManager />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/agent-login" element={<AgentLogin />} />
              <Route path="/banner-section" element={<BannerSection />} />
              <Route path="/blog-section" element={<BlogSection />} />
              <Route path="/brand-section" element={<BrandSection />} />
              <Route path="/breadcrumb" element={<BreadCrumbSection />} />
              <Route path="/business-section" element={<BusinessSection />} />
              <Route path="/contact-section" element={<ContactSection />} />
              <Route path="/feature-section" element={<FeatureSection />} />
              <Route path="/footer-section" element={<FooterSection />} />
              <Route
                path="/user-login-section"
                element={<UserLoginSection />}
              />
              <Route
                path="/merchant-login-section"
                element={<MerchantLoginSection />}
              />
              <Route path="/overview-section" element={<OverViewSection />} />
              <Route path="/policy-pages" element={<PolicyPages />} />
              <Route path="/service-section" element={<ServiceSection />} />
              <Route path="/sleep-mode" element={<SleepMode />} />
              <Route path="/socail-icons" element={<SocialIcons />} />
              <Route path="/subscribe-section" element={<SubscribeSection />} />
              <Route
                path="/testimonial-section"
                element={<TestimonialSection />}
              />
              <Route path="/choose-us-section" element={<WhyChooseSection />} />
              <Route
                path="/all-notification-template"
                element={<AllNotificationTemplate />}
              />
              <Route
                path="/sms-settings"
                element={<SmsNotificationSettings />}
              />
              <Route
                path="/email-settings"
                element={<EmailNotificationSettings />}
              />
              <Route
                path="/push-notification-settings"
                element={<PushNotificationSettings />}
              />
              <Route
                path="/notification-template"
                element={<NotificationTemplate />}
              />
              <Route
                path="/all-global-notification-template"
                element={<AllGlobalNotificationTemplate />}
              />
              <Route
                path="/logout-from-devices"
                element={<LogoutFromAllDevices />}
              />
              {/*Extra  */}
              <Route path="/plugins" element={<ApiPlugins />} />
              <Route path="/maintainance-mode" element={<MaintenanceMode />} />
              <Route path="/gdpr-cookie" element={<GdprCookie />} />
              <Route
                path="/system/application"
                element={<ApplicationInformation />}
              />
              <Route path="/system/server" element={<ServerInformation />} />
              <Route path="/system/cache" element={<CacheInformation />} />
              <Route path="/custom-css" element={<CustomCss />} />
              <Route path="/listed-reports" element={<ListedReport />} />
              {/*  */}
              <Route path="/logout-from-all" element={<LogoutDevices />} />
              <Route path="/profile-setting" element={<UserProfile />} />
              <Route path="/change-password" element={<ChangePassword />} />
              {/*  */}
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
