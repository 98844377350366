import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Tickets {
  getAllTickets = (payload) => {
    const url = `${API_URL}api/v1/admin/support-tickets`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  getCloseTickets = (payload) => {
    const url = `${API_URL}api/v1/admin/support-tickets?status=${"closed"}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  getPendingTickets = (payload) => {
    const url = `${API_URL}api/v1/admin/support-tickets?status=${"pending"}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  getAnsweredTickets = (payload) => {
    const url = `${API_URL}api/v1/admin/support-tickets?status=${"answered"}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  // getNewsDetails = (id) => {
  //   const url = `${API_URL}api/v1/utility/admin/news/${id}/`;
  //   return axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Token ${ACCESS_TOKEN}`,
  //       },
  //     })
  //     .then(function (response) {
  //       if (response.status === 200 || response.status === 201) {
  //         return response.data;
  //       } else {
  //         return {
  //           data: {
  //             results: [],
  //           },
  //         };
  //       }
  //     })
  //     .catch(function (error) {
  //       return error.response?.data;
  //     });
  // };

  // editNews = (payload, id) => {
  //   const url = `${API_URL}api/v1/utility/admin/news/${id}/`;
  //   return axios({
  //     url,
  //     method: "PATCH",
  //     data: payload,
  //     headers: {
  //       Authorization: `Token ${ACCESS_TOKEN}`,
  //     },
  //   })
  //     .then(function (response) {
  //       if (response.status === 200 || response.status === 201) {
  //         return response.data;
  //       } else {
  //         return {
  //           data: {
  //             results: [],
  //           },
  //         };
  //       }
  //     })
  //     .catch(function (error) {
  //       return error.response?.data;
  //     });
  // };

  // deleteNews = (id) => {
  //   const url = `${API_URL}api/v1/utility/admin/news/${id}/`;
  //   return axios
  //     .delete(url, {
  //       headers: {
  //         Authorization: `Token ${ACCESS_TOKEN}`,
  //       },
  //     })
  //     .then(function (response) {
  //       if (response.status === 200 || response.status === 201) {
  //         return response.data;
  //       } else {
  //         return {
  //           data: {
  //             results: [],
  //           },
  //         };
  //       }
  //     })
  //     .catch(function (error) {
  //       return error.response?.data;
  //     });
  // };
}
