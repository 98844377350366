/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { message } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layout";
import Login from "../components/auth/log-in";
import SignIn from "../components/auth/sign-in";
import EmailVerificationModal from "../components/auth/email-verification";
import ResetPassword from "../components/auth/reset-password";
import API from "../utils/api";
import { getDataManager,getErrorMessage } from "../utils/helper.functions";
import { setUserData,setProfileData,setUnreadNotifications } from "../utils/redux/actions";
import "./fonts.scss"
import "./App.scss";

const App = () => {

  const profile = new API.Profile();
  const notification = new API.Notifications();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => {
    return state?.app;
  });

  const savedUserData = JSON.parse(
    localStorage.getItem("wallet-admin-panel-user-data")
  );

  useEffect(() => {
    dispatch(setUserData(savedUserData));
    fetchUnreadNotiCount()
    fetchProfileData()
  }, []);

 

  const fetchProfileData = async () => {
    return getDataManager(profile?.getProfileData).then((x) => {
      if (x?.status === true) {
        dispatch(setProfileData(x?.data));
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 3,
        });
      }
    });
  };
   const fetchUnreadNotiCount = async () => {
    return getDataManager(notification?.getUnreadNotifications).then((x) => {
      if (x?.status === true) {
        dispatch(setUnreadNotifications(x?.data));
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 3,
        });
      }
    });
  };
  const isLoggedIn = !!savedUserData?.token || !!userData?.token;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }, [isLoggedIn]);

  return (
    <div className="wallet-main-container">
      {isLoggedIn ? <AdminLayout /> :
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/email-verification" element={<EmailVerificationModal />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>}
    </div>
  );
};

export default App;
