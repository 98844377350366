import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Withdraws {
  getAllWithdrawsMethod = (name) => {
    return axios
      .get(`${API_URL}api/v1/admin/withdraw-methods?search=${name || ""}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getAllWithdraws = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/withdraws?user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,

        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getApprovedWithdrawList = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/withdraws?status=${"Success"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getRejectedWithdrawList = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/withdraws?status=${"Rejected"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getPendingWithdrawList = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/withdraws?status=${"Pending"}&user_type=${
          payload?.userType || ""
        }&search=${payload?.userName || ""}&currency=${
          payload?.currency || ""
        }&start_date=${payload?.start_date || ""}&end_date=${
          payload?.end_date || ""
        }`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  addWithdrawMethod = (payload = {}) => {
    return axios
      .post(
        `${API_URL}api/v1/admin/withdraw-methods`,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  updateWithdrawMethod = (payload = {}, id) => {
    return axios
      .patch(
        `${API_URL}api/v1/admin/withdraw-methods/${id}`,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getMethodDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/withdraw-methods/${id}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getWithdrawDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/admin/withdraws/${id}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getApprovedWithdraws = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/withdraws/total-approved-withdraw-amount`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getPendingWithdraws = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/admin/withdraws/total-pending-withdraw-amount`,

        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getRejectedWithdraws = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/admin/withdraws/total-rejected-withdraw-amount`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  //enabel disable withdrawmethod
  disableMethod = (id) => {
    const url = `${API_URL}api/v1/admin/withdraw-methods/${id}/disable`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  enableMethod = (id) => {
    const url = `${API_URL}api/v1/admin/withdraw-methods/${id}/enable`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // accept reject pending withdraw
  approveWithdrawRequest = (id) => {
    const url = `${API_URL}api/v1/admin/withdraws/${id}/approve`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  rejectWithdrawRequest = (id) => {
    const url = `${API_URL}api/v1/admin/withdraws/${id}/reject`;
    return axios({
      url,
      method: "PATCH",
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
