import axios from "axios";
import { API_URL, ACCESS_TOKEN, Language } from "../constants";

export default class Auth {
  signUpUser = (payload) => {
    const url = `${API_URL}api/v1/admin/admin-list`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  loginUser = (payload) => {
    const url = `${API_URL}api/v1/admin/auth/login`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": Language || "en",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  validate2FaSecurity = (payload) => {
    const url = `${API_URL}api/v1/users/auth/2fa/validate-otp`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  accountVerify = (payload) => {
    const url = `${API_URL}api/v1/auth/account/verify/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  codeValidate = (payload) => {
    const url = `${API_URL}api/v1/users/auth/verify-email`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  resendVerificationCode = (payload) => {
    const url = `${API_URL}api/v1/auth/verification/resend/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  forgetPassword = (payload) => {
    const url = `${API_URL}api/v1/users/auth/forget-password`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  forgetPasswordConfirm = (payload) => {
    const url = `${API_URL}api/v1/users/auth/verify-forget-password`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  changePassword = (payload) => {
    const url = `${API_URL}api/v1/auth/change/password/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${(JSON.parse(localStorage.getItem("last-stand-admin-panel-user-data")) || {}).token
              }`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  confirmPassword = (payload) => {
    const url = `${API_URL}api/v1/auth/forget/password/confirm/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getCountry = () => {
    return axios
      .get(`${API_URL}api/v1/common/countries`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  get2FaSecurity = () => {
    return axios
      .get(`${API_URL}api/v1/users/auth/2fa/generate`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  uploadFile = (formData) => {
    const url = `${API_URL}api/v1/auth/documents/upload/`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
// manage sections agent,merchant,user login and reg
getMerchantAuth = (payload = {}) => {
  const url = `${API_URL}api/v1/admin/auth-backgrounds/merchant-auth-background`;
  return axios
    .get(url, {
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return {
          data: {
            results: [],
          },
        };
      }
    })
    .catch(function (error) {
      return error.response?.data;
    });
};
updateMerchantAuth  = (formData, id) => {
  const url = `${API_URL}api/v1/admin/auth-backgrounds/merchant-auth-background/${id}`;
  return axios({
    url,
    method: "PATCH",
    data: formData,
    headers: {
      Authorization: `Token ${ACCESS_TOKEN}`,
    },
  })
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return {
          data: {
            results: [],
          },
        };
      }
    })
    .catch(function (error) {
      return error.response?.data;
    });
};
getAgentAuth = (payload = {}) => {
  const url = `${API_URL}api/v1/admin/auth-backgrounds/agent-auth-background`;
  return axios
    .get(url, {
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return {
          data: {
            results: [],
          },
        };
      }
    })
    .catch(function (error) {
      return error.response?.data;
    });
};
updateAgentAuth  = (formData, id) => {
  const url = `${API_URL}api/v1/admin/auth-backgrounds/agent-auth-background/${id}`;
  return axios({
    url,
    method: "PATCH",
    data: formData,
    headers: {
      Authorization: `Token ${ACCESS_TOKEN}`,
    },
  })
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return {
          data: {
            results: [],
          },
        };
      }
    })
    .catch(function (error) {
      return error.response?.data;
    });
};
getUserAuth = (payload = {}) => {
  const url = `${API_URL}api/v1/admin/auth-backgrounds/user-auth-background`;
  return axios
    .get(url, {
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return {
          data: {
            results: [],
          },
        };
      }
    })
    .catch(function (error) {
      return error.response?.data;
    });
};
updateUserAuth  = (formData, id) => {
  const url = `${API_URL}api/v1/admin/auth-backgrounds/user-auth-background/${id}`;
  return axios({
    url,
    method: "PATCH",
    data: formData,
    headers: {
      Authorization: `Token ${ACCESS_TOKEN}`,
    },
  })
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return {
          data: {
            results: [],
          },
        };
      }
    })
    .catch(function (error) {
      return error.response?.data;
    });
};
}
