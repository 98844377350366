import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";

const initialState = {
  userData: {},
  profileData:{},
  emailOtp:{},
  adminWallets:[],
  unreadNotifications:{},
  isDefaultCurrency:{}
};

export default handleActions(
  {
    [actions.setUserData](state, { payload }) {
      return produce(state, (state) => {
        state.userData = payload;
      });
    },
    [actions.setProfileData](state, { payload }) {
      return produce(state, (state) => {
        state.profileData = payload;
      });
    },
    [actions.setAdmintWallets](state, { payload }) {
      return produce(state, (state) => {
        state.adminWallets = payload;
      });
    },
    [actions.setOtpEmail](state, { payload }) {
      return produce(state, (state) => {
        state.emailOtp = payload;
      });
    },
    [actions.setUnreadNotifications](state, { payload }) {
      return produce(state, (state) => {
        state.unreadNotifications = payload;
      });
    },
    [actions.setIsDefaultCurrency](state, { payload }) {
      return produce(state, (state) => {
        state.isDefaultCurrency = payload;
      });
    },
  },
  initialState
);
