import { createAction } from "redux-actions";

export const setUserData = createAction(
  "SET_USER_DATA",
  (userData) => userData
);
export const setProfileData = createAction(
  "SET_PROFILE_DATA",
  (profileData) => profileData
);

export const setOtpEmail = createAction(
  "SET_OTP_EMAIL",
  (emailOtp) => emailOtp
);
export const setAdmintWallets = createAction(
  "SET_ADMIN_WALLETS",
  (adminWallets) => adminWallets
);

export const setUnreadNotifications = createAction(
  "SET_UNREAD_NOTIFICATIONS",
  (unreadNotifications) => unreadNotifications
);
export const setIsDefaultCurrency = createAction(
  "SET_IS_DEFAULT_CURRENCY",
  (isDefaultCurrency) => isDefaultCurrency
);